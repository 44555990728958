<template>
  <Header />
  <main>
    <div class="cont">
      <FacilityName :facilityId="Number($route.query.facility_id)"/>
      <FacilityAdminMenu :facilityId="Number($route.query.facility_id)"/>
      <h1>入居者一覧</h1>
      <article class="formitems">
        <div class="form">
          <div class="main">
            <p>
              <label>部屋名</label>
              <input
                id="searchRoomName"
                placeholder="部屋名"
                autocomplete="off"
                type="text"
                v-model="searchRoomName"
              >
            </p>
            <p>
              <label>氏名</label>
              <input
                id="searchName"
                placeholder="氏名"
                autocomplete="off"
                type="text"
                v-model="searchName"
              >
            </p>
            <p>
              <label>ラベル</label>
              <select v-model="selectedMainLabel">
                <option value="">指定なし</option>
                <option v-for="label in mainLabels" :key="label" :value="label">{{ label }}</option>
              </select>
            </p>
            <p>
              <label>備考</label>
              <select v-model="selectedSubLabel">
                <option value="">指定なし</option>
                <option v-for="label in subLabels" :key="label" :value="label">{{ label }}</option>
              </select>
            </p>
          </div>

          <div class="send">
            <p><button v-on:click="searchResidents">絞り込み</button></p>
          </div>
        </div>
      </article>
      <div class="noresults" v-if="hitResidents.length === 0"><span>該当なし</span></div>
      <ResidentsSearchResult :residents="hitResidents" :facilityId="Number($route.query.facility_id)"></ResidentsSearchResult>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import FacilityAdminMenu from './FacilityAdminMenu'
import FacilityName from './FacilityName'
import { apiGet } from '../../api'
import ResidentsSearchResult from './ResidentsSearchResult'

export default {
  name: 'Residents',
  components: {
    Header,
    Footer,
    FacilityAdminMenu,
    FacilityName,
    ResidentsSearchResult
  },
  data() {
    return {
      searchRoomName: '',
      searchName: '',
      selectedMainLabel: '',
      selectedSubLabel: '',
      mainLabels: [],
      subLabels: [],
      hitResidents: [],
    }
  },
  async mounted() {
    this.loadLabels()
    this.loadResidents()
  },
  methods: {
    loadResidents: async function() {
      if (!Number.isInteger(Number(this.$route.query.facility_id))) {
        return
      }
      try {
        const response = await apiGet(`/api/target_user/search?facility_id=${this.$route.query.facility_id}`, this.$router.app.$cookies)
        this.hitResidents = response.data
      } catch (error) {
        console.error(error)
      }
    },
    searchResidents: async function() {
      let query = `facility_id=${this.$route.query.facility_id}`
      if (this.searchRoomName !== '') {
        query += `&room_name=${encodeURIComponent(this.searchRoomName)}`
      }
      if (this.searchName !== '') {
        query += `&name=${encodeURIComponent(this.searchName)}`
      }
      if (this.selectedMainLabel !== '') {
        query += `&main_label=${encodeURIComponent(this.selectedMainLabel)}`
      }
      if (this.selectedSubLabel !== '') {
        query += `&sub_label=${encodeURIComponent(this.selectedSubLabel)}`
      }
      const response = await apiGet(`/api/target_user/search?${query}`, this.$router.app.$cookies)
      this.hitResidents = response.data
    },
    loadLabels: async function() {
      const response = await apiGet(`/api/facility_label?facility_id=${this.$route.query.facility_id}`, this.$router.app.$cookies)
      const fetchedMainLabels = []
      const fetchedSubLabels = []
      response.data.forEach(label => {
        if (label.main !== '' && !fetchedMainLabels.includes(label.main)) {
          fetchedMainLabels.push(label.main)
        }
        if (label.sub !== '' && !fetchedSubLabels.includes(label.sub)) {
          fetchedSubLabels.push(label.sub)
        }
      })

      this.mainLabels = fetchedMainLabels
      this.subLabels = fetchedSubLabels
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .form {
    width: 100%;
    padding: 0 1vw 3vw;
  }
  .form > div {
    display: flex;
    flex-flow: row wrap;
    font-size: 3vw;
    padding-bottom: 1em;
  }
  .form div p {
    width: 100%;
    text-align: center;
  }
  .form div label {
    display: block;
    text-align: left;
    width: 100%;
    margin: auto;
    padding-top: 1em;
  }
  .form div input,
  .form div select {
    font-size: 3vw;
    width: 100%;
    margin: auto;
    padding: .2em;
  }
  .form .btn {
    text-align: center;
  }
  .form .btn button {
    display: block;
    margin: auto;
  }
  .noresults {
    text-align: center;
    font-size: 4vw;
    padding: 2em 0 4em;
  }
  .noresults span {
    display: block;
    width: 80%;
    margin: auto;
    padding: 1em 0;
    border-top: rgb(200,200,200) double 3px;
    border-bottom: rgb(200,200,200) double 3px;
  }
}
@media all and (min-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
  }
  .form > div {
    display: flex;
    flex-flow: row wrap;
    font-size: 1vw;
    padding-bottom: 1em;
  }
  .form div p {
    padding-right: 1em;
  }
  .form div label {
    display: block;
  }
  .form div input,
  .form div select {
    font-size: 1vw;
    padding: .2em;
  }
  .form .btn {
    text-align: center;
  }
  .noresults {
    text-align: center;
    font-size: 1vw;
    padding: 2em 0 4em;
  }
  .noresults span {
    display: block;
    width: 60%;
    margin: auto;
    padding: 1em 0;
    border-top: rgb(200,200,200) double 3px;
    border-bottom: rgb(200,200,200) double 3px;
  }
}
</style>
