<template>
  <div class="detail" v-if="detail">
    <div class="modal-overlay-d" v-on:click="dismissDetail"></div>
    <section class="detail-cont">
      <vue-element-loading :active="isLoading" background-color="rgba(255, 255, 255, .5)" color="#2c3e50"/>
      <div class="modal_header">
        {{ detail.name }}さんの詳細
        <div class="modal_close" v-on:click="dismissDetail">
          <div class="modal_close_icon"><span></span></div>
        </div>
      </div>

      <div class="chart">
        <div class="live">
          <div class="live-btn" v-if="!getRealTimeDataFlag" v-on:click="getRealTimeDataStart"><button>リアルタイムデータを確認する</button></div>
          <div class="chart-container">
            <h2 v-if="$router.app.userDetail.is_demo"><span>室内の活動</span></h2>
            <h2 v-else><span>室内の活動</span>{{ realTimeDataRestTimeString }}（データ取得は1回60秒まで、1日300秒までです）</h2>

            <ActivityChart v-if="getRealTimeDataFlag" :data="activityChartData" :startTime="startTime"/>
          </div>

          <div class="chart-container">
            <h2 v-if="$router.app.userDetail.is_demo"><span>呼吸数</span></h2>
            <h2 v-else><span>呼吸数</span>{{ realTimeDataRestTimeString }}（データ取得は1回60秒まで、1日300秒までです）</h2>

            <p v-if="averageBreathingString" class="averagebreath">{{ averageBreathingString }}</p>
            <BreathingChart v-if="getRealTimeDataFlag" :data="breathingChartData" :startTime="startTime"/>
          </div>
        </div>

        <SleepCondition :targetUserId="data.targetUserId"/>
      </div>

      <div class="note">
        <em>メモ</em>
        <ul ref="note">
          <li v-for="memo in memos" :key="memo.id">
            <span class="date">{{ formatTime(memo.created_at) }}</span>
            <p class="memo">{{ memo.body }}</p>
            <em class="describer">{{ memo.writer_name }}</em>
          </li>
        </ul>
        <div class="memo-ipt">
          <input type="text" v-model="newMemoBody" placeholder="本文（必須）">
          <input type="text" v-model="newMemoWriter" placeholder="記入者（必須）">
          <button v-on:click="sendMemo">メモ欄に記入</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { apiGet, apiPost } from '../../api'
import ActivityChart from './ActivityChart'
import BreathingChart from './BreathingChart'
import SleepCondition from './SleepCondition/SleepCondition'

export default {
  components: {
    ActivityChart,
    BreathingChart,
    SleepCondition
  },
  data () {
    return {
      detail: null,
      memos: [],
      newMemoBody: '',
      newMemoWriter: '',
      activityChartData: {
        datasets: []
      },
      breathingChartData: {
        datasets: []
      },
      averageBreathingString: '0.00回/分',
      startTime: new Date(),
      realTimeDataRestTimeString: '',
      isLoading: false,
      getRealTimeDataFlag: false,
      realTimeDataCount: 0,
      targetUserId: null,
    }
  },
  props: {
    data: {
      type: Object, // contains targetUserId, startTime
      required: true
    },
    targetUsers: {
      type: Array,
      required: true
    },
  },
  watch: {
    data: async function(newVal) {
      const targetUser = this.targetUsers.find(element => element.id === newVal.targetUserId)
      this.targetUserId = newVal.targetUserId
      this.detail = targetUser
      this.startTime = newVal.startTime
      this.getRealTimeDataFlag = false
      this.realTimeDataCount = 0
      this.loadMemo()
    },
  },
  methods: {
    formatTime: function(datestr) {
      const unixtime = Date.parse(datestr)
      const date = new Date(unixtime)
      return date.toLocaleString('ja-JP')
    },
    showSleepCondition: function() {
      window.open(`/sleep_condition?id=${this.data.targetUserId}`)
    },
    sendMemo: async function() {
      try {
        if (this.newMemoBody === '' || this.newMemoWriter === '') {
          alert('本文と記入者の両方を記入してください')
          return
        }
        const response = await apiPost('/api/target_user/memo', {
          target_user_id: this.data.targetUserId,
          body: this.newMemoBody,
          writer_name: this.newMemoWriter
        }, this.$router.app.$cookies)

        if (response.status === 200) {
          this.newMemoBody = ''
          this.newMemoWriter = ''
          this.loadMemo()
        }
      } catch (error) {
        console.error(error.value)
      }
    },
    loadMemo: async function() {
      try {
        const response = await apiGet(`/api/target_user/memo?target_user_id=${this.data.targetUserId}`, this.$router.app.$cookies)
        this.memos = response.data

        const memoElement = this.$refs.note
        memoElement.scrollTop = 0
      } catch (error) {
        console.error(error.value)
      }
    },
    startFetchingRealtimeSensorData: async function(currentTargetUserId) {
      this.isLoading = true
      const _this = this

      const heartbeatTimeInterval = 5000
      const heartbeatCountMax = 12
      let heartbeatCount = 1
      this.heartbeatRealtimeSensorData()
      const heartbeatIntervalId = setInterval(function() {
        if (_this.detail === null || _this.data.targetUserId !== currentTargetUserId || heartbeatCount > heartbeatCountMax) {
          clearInterval(heartbeatIntervalId)
          return
        }
        _this.heartbeatRealtimeSensorData()
        heartbeatCount += 1
      }, heartbeatTimeInterval)

      const loadRealtimeDataTimeIntarval = 1000
      this.loadRealtimeSensorData()
      const intervalId = setInterval(function() {
        if (_this.detail === null || _this.data.targetUserId !== currentTargetUserId) {
          clearInterval(intervalId)
          _this.isLoading = false
          return
        }
        _this.loadRealtimeSensorData()
      }, loadRealtimeDataTimeIntarval)
    },
    heartbeatRealtimeSensorData: async function() {
      try {
        if (this.realTimeDataCount > 600 && !this.$router.app.userDetail.is_demo) {
          return
        }
        const response = await apiPost('/api/rpm_sensor_data/heartbeat',{
          target_user_id: this.data.targetUserId
        }, this.$router.app.$cookies)
        this.realTimeDataCount = response.data.todays_count
      } catch (error) {
        console.error(error.value)
      }
    },
    loadRealtimeSensorData: async function() {
      try {
        const response = await apiGet(`/api/rpm_sensor_data?target_user_id=${this.data.targetUserId}`, this.$router.app.$cookies)

        const activityLineData = []
        var maxStatisticsData = {}
        for (let i = 0; i < response.data.length; i++) {
          const current = response.data[i]
          if (Object.keys(maxStatisticsData).length === 0) {
            maxStatisticsData = {
              x: new Date(current.timestamp * 1000),
              y: Math.max(0, current.statistics),
              timestamp: current.timestamp
            }
          }

          if (current.timestamp === maxStatisticsData.timestamp) {
            if (current.statistics > maxStatisticsData.y) {
              maxStatisticsData = {
                x: new Date(current.timestamp * 1000),
                y: Math.max(0, current.statistics),
                timestamp: current.timestamp
              }
            }
          } else {
            activityLineData.push(maxStatisticsData)
            maxStatisticsData = {
              x: new Date(current.timestamp * 1000),
              y: Math.max(0, current.statistics),
              timestamp: current.timestamp
            }
          }

          if (i === response.data.length-1) {
            activityLineData.push(maxStatisticsData)
          }
        }

        this.activityChartData = {
          datasets: [
            {
              type: 'line',
              data: activityLineData,
            },
          ]
        }

        const breathingLineData = response.data.map(data => {
          return {
            x: new Date(data.timestamp * 1000),
            y: data.rate / 100
          }
        })

        this.breathingChartData = {
          datasets: [
            {
              type: 'line',
              data: breathingLineData,
            },
          ]
        }

        let breathingCount = 0
        let breathingTotal = 0
        breathingLineData.forEach((data) => {
          if (data.x >= this.startTime && data.y > 0) {
            breathingCount += 1
            breathingTotal += data.y
          }
        })
        if (breathingCount > 0) {
          this.averageBreathingString = `${(breathingTotal/breathingCount).toFixed(2)}回/分`
        } else {
          this.averageBreathingString = '0.00回/分'
        }

        if (this.realTimeDataCount) {
          this.realTimeDataRestTimeString = `残り${Math.max(Math.floor((600 - this.realTimeDataCount) / 2), 0)}秒`
        }
      } catch (error) {
        console.error(error.value)
      }

      this.isLoading = false
    },
    dismissDetail: function() {
      this.detail = null
      this.realTimeDataRestTimeString = ''
    },
    getRealTimeDataStart: function() {
      this.getRealTimeDataFlag = true
      this.startFetchingRealtimeSensorData(this.targetUserId)
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .chart .live {
    position: relative;
  }
  .chart .live .live-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, .8);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .chart-container {
    padding: 4em 1em;
    position: relative;
  }
  .chart-container .averagebreath {
    font-size: 3.6vw;
    position: absolute;
    top: 4em;
    right: .5em;
    padding: .5em;
    background: rgba(255,255,255, .5);
  }
  .velmld-parent {
    position: fixed!important;
  }
}
@media all and (min-width: 480px) {
  .chart .live {
    position: relative;
  }
  .chart .live .live-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, .8);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .chart-container {
    padding: 4em;
    position: relative;
  }
  .chart-container .averagebreath {
    font-size: 16px;
    position: absolute;
    top: 4em;
    left: 2em;
    padding: .5em;
    background: rgba(245,245,245, .5);
  }
  .velmld-parent {
    position: fixed!important;
  }
  .note .memo-ipt {
    padding: 15px 0 0;
  }
  .note .memo-ipt input {
    font-size: 1vw;
    display: block;
    width: 100%;
    margin-bottom: .5em;
  }
  .note input[type=text] {
    display: inline-block;
    margin-right: 1em;
  }
  .note button {
    display: inline-block;
    background: rgb(255,255,255);
    border: rgb(180,180,180) solid 1px;
    border-radius: 5px;
    font-size: 1vw;
    padding: .2em 2em;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}
</style>
