<template>
  <div class="list">
    <div class="legend">
      <ul>
        <li class="alert"><span>異常検知</span></li>
        <li class="active"><span>通常状態</span></li>
        <li class="sleeping"><span>睡眠中</span></li>
        <li class="offline"><span>オフライン</span></li>
      </ul>
      <p>上記ステータスの詳細は<a href="/help">ヘルプ</a>を御覧ください。</p>
    </div>
    <div class="appearance">
      <ul>
        <li><label><input type="radio" :value="0" v-model="listType" />アイコン表示</label></li>
        <li><label><input type="radio" :value="1" v-model="listType" />リスト表示</label></li>
      </ul>
    </div>
    <section class="list-cont">
      <ul v-if="listType === 0">
        <li v-on:click="showUserDetail(targetUser.id)" v-for="targetUser in targetUsers" :key="targetUser.id" :class='getStatusClass(targetUser.status)'>
          <div class="id">{{ targetUser.room_name }}</div>
          <div class="name">{{ targetUser.name }}</div>
        </li>
      </ul>
      <ol v-if="listType === 1">
        <li v-on:click="showUserDetail(targetUser.id)" v-for="targetUser in targetUsers" :key="targetUser.id" :class='getStatusClass(targetUser.status)'>
          <p>
            <span class="id">【{{ targetUser.room_name }}】</span>
            <span class="name">{{ targetUser.name }}</span>
          </p>
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
import { targetUserStatus } from '../../constants'

export default {
  props: {
    targetUsers: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      listType: 0 // 0: icon, 1: list
    }
  },
  methods: {
    showUserDetail: function(targetUserId) {
      this.$emit("showUserDetail", targetUserId)
    },
    renderSinceLastExisted: function(sinceLastExisted) {
      if (sinceLastExisted < 60) {
        return '通常'
      }
      const minute = Math.floor(sinceLastExisted / 60)
      if (minute > 60) {
        return '異常 1時間以上経過'
      } else {
        return `異常 ${minute}分経過`
      }
    },
    getStatusClass: function(currentStatus) {
      if (currentStatus === targetUserStatus.active) {
        return 'active'
      } else if (currentStatus === targetUserStatus.inactive) {
        return 'alert'
      } else if (currentStatus === targetUserStatus.sleeping) {
        return 'sleeping'
      } else if (currentStatus === targetUserStatus.offline) {
        return 'offline'
      }
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .list .appearance {
  font-size: 3.8vw;
  margin: 1em 0 1em;
  }
  .list .appearance ul {
    display: flex;
    flex-flow: row wrap;
  }
  .list .appearance ul li {
    padding-right: 1em;
  }
  .legend ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    font-size: 3.4vw;
  }
  .legend ul li {
    padding-left: 1em;
  }
  .legend ul li.alert span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(240, 150, 50);
    margin-right: .5em;
  }
  .legend ul li.active span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(255, 255, 255);
    border: rgb(200,200,200) solid 1px;
    margin-right: .5em;
  }
  .legend ul li.sleeping span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(155,200,255);
    margin-right: .5em;
  }
  .legend ul li.offline span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(200, 200, 200);
    margin-right: .5em;
  }
  .legend p {
    text-align: right;
    font-size: 3vw;
  }
}
@media all and (min-width: 480px) {
  .list .appearance {
    font-size: 1.2vw;
    margin: -2em 0 1em;
  }
  .list .appearance ul {
    display: flex;
    flex-flow: row wrap;
  }
  .list .appearance ul li {
    padding-right: 1em;
  }
  .list .appearance ul li label {
    cursor: pointer;
  }
  .legend ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    font-size: 1vw;
  }
  .legend ul li {
    padding-left: 1em;
  }
  .legend ul li.alert span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(240, 150, 50);
    margin-right: .5em;
  }
  .legend ul li.active span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(255, 255, 255);
    border: rgb(200,200,200) solid 1px;
    margin-right: .5em;
  }
  .legend ul li.sleeping span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(155,200,255);
    margin-right: .5em;
  }
  .legend ul li.offline span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(200, 200, 200);
    margin-right: .5em;
  }
  .legend p {
    text-align: right;
    font-size: 1vw;
  }
}
</style>
