<template>
  <Header />
  <main class="SelectFacility">
    <div class="cont">
      <BackButton/>
      <h1>施設選択</h1>
      <section>
        <div class="form-item">
          <span>法人を選択してください</span>
          <select v-model="selectedCompanyId">
            <option v-for="company in companies" :key="company.id" :value="company.id" :selected="company.id === selectedCompanyId">{{ company.name }}</option>
          </select>
        </div>
        <div class="select-id">
          <div>施設を選択してください</div>
          <ul>
            <li v-for="facility in facilities" :key="facility.id" v-on:click="selectFacility(facility.id)"><span>{{ facility.name }}</span></li>
          </ul>
        </div>
      </section>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet } from '../../api'

export default {
  name: 'SelectFacility',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      companies: [],
      selectedCompanyId: null,
      facilities: [],
    }
  },
  mounted() {
    const loadCompany = async () => {
      try {
        const response = await apiGet('/api/company', this.$router.app.$cookies)
        this.companies = response.data
        if (this.companies.length > 0) {
          this.selectedCompanyId = this.companies[0].id
        }
      } catch (error) {
        console.log(error)
      }
    }
    loadCompany()
  },
  watch: {
    selectedCompanyId: async function(newVal) {
      const response = await apiGet(`/api/facility?company_id=${newVal}`, this.$router.app.$cookies)
      this.facilities = response.data
    }
  },
  methods: {
    selectFacility: function(facilityId) {
      this.$router.push(`/facility_manager?id=${facilityId}`)
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .SelectFacility section .form-item {
    font-size: 4vw;
    padding: 0 0 2em 0;
  }
  .SelectFacility section .form-item span {
    display: block;
    padding-bottom: .5em;
  }
  .SelectFacility section .form-item select {
    font-size: 4vw;
    padding: .5em 1.5em;
    background: rgb(255,255,255);
    border: rgb(190,190,190) solid 1px;
    border-radius: .5em;
    cursor: pointer;
  }
  .SelectFacility section .select-id {
    font-size: 4vw
  }
  .SelectFacility section .select-id div {
    padding-bottom: .5em;
  }
  .SelectFacility section .select-id ul li {
    padding: 0 0 1em;
  }
  .SelectFacility section .select-id ul li span {
    display: block;
    padding: .8em 1.5em;
    background: rgb(255,255,255);
    border: rgb(190,190,190) solid 1px;
    border-radius: .5em;
    cursor: pointer;
  }
}
@media all and (min-width: 480px) {
  .SelectFacility section {
    display: flex;
    flex-flow: row wrap;
  }
  .SelectFacility section .form-item {
    font-size: 1.4vw;
    padding: 0 50px 1em 0;
  }
  .SelectFacility section .form-item span {
    display: block;
    padding-bottom: .5em;
  }
  .SelectFacility section .form-item select {
    font-size: 1.4vw;
    padding: .5em 1.5em;
    background: rgb(255,255,255);
    border: rgb(190,190,190) solid 1px;
    border-radius: .5em;
    cursor: pointer;
  }
  .SelectFacility section .select-id {
    font-size: 1.4vw
  }
  .SelectFacility section .select-id div {
    padding-bottom: .5em;
  }
  .SelectFacility section .select-id ul li {
    padding: 0 0 .5em;
  }
  .SelectFacility section .select-id ul li span {
    display: block;
    padding: .5em 1.5em;
    background: rgb(255,255,255);
    border: rgb(190,190,190) solid 1px;
    border-radius: .5em;
    cursor: pointer;
  }
}
</style>
