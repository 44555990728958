<template>
  <div class="chart-container">
    <h2><span>タイムライン</span>※無人の場合でも活動ありと表示されることがあります。</h2>
    <select v-model="timelineLength">
      <option :value="24">24時間</option>
      <option :value="12">12時間</option>
      <option :value="6">6時間</option>
    </select>
    <TimelineChart :data="timelineChartData" :begin="timelineBegin" :end="timelineEnd"/>
    <div class="legend">
      <div class="legend-item">
        <div class="active-color-sample"></div>
        <span>活動あり</span>
      </div>
      <div class="legend-item">
        <div class="sleep-color-sample"></div>
        <span>睡眠中</span>
      </div>
      <div class="legend-item">
        <div class="non-active-color-sample"></div>
        <span>活動なし</span>
      </div>
    </div>
  </div>

  <div class="chart-container">
    <h2><span>睡眠データ</span></h2>
    <SleepChart :data="sleepChartData" :begin="timelineBegin" :end="timelineEnd"/>
    <div class="legend">
      <div class="legend-item">
        <div class="non-rem-color-sample"></div>
        <span>ノンレム睡眠</span>
      </div>
      <div class="legend-item">
        <div class="rem-color-sample"></div>
        <span>レム睡眠</span>
      </div>
      <div class="legend-item">
        <div class="awake-color-sample"></div>
        <span>目が覚めている</span>
      </div>
    </div>
  </div>

  <div class="chart-container">
    <h2><span>一週間のデータ</span>※無人の場合でも活動ありと表示されることがあります。</h2>
    <OneWeekTimelineChart :data="oneWeekChartData" :begin="oneweekTimelineBegin" :end="oneweekTimelineEnd"/>
    <div class="legend">
      <div class="legend-item">
        <div class="active-color-sample"></div>
        <span>活動あり</span>
      </div>
      <div class="legend-item">
        <div class="sleep-color-sample"></div>
        <span>睡眠中</span>
      </div>
      <div class="legend-item">
        <div class="non-active-color-sample"></div>
        <span>活動なし</span>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet } from '../../../api'
import TimelineChart from './TimelineChart'
import SleepChart from './SleepChart'
import OneWeekTimelineChart from './OneWeekTimelineChart'

const timelineColorMap = {
  '活動あり': 'rgba(255, 205, 85, 1.0)',
  '活動なし': 'rgba(190, 190, 190, 1.0)',
  '睡眠中': 'rgba(100, 105, 205, 1.0)',
}

const sleepColorMap = [
  'rgba(255, 215, 0, 1.0)', // awake
  'rgba(150, 190, 250, 1.0)', // non rem
  'rgba(55, 90, 180, 1.0)', // rem
]

const sleepLabels = [
  '目が覚めている',
  'ノンレム睡眠',
  'レム睡眠'
]

const oneDayUnixTime = 24 * 60 * 60 * 1000

export default {
  name: 'SleepCondition',
  props: {
    targetUserId: {
      type: Number,
      require: true
    }
  },
  components: {
    TimelineChart,
    SleepChart,
    OneWeekTimelineChart
  },
  mounted() {
    this.loadTimelineData()
  },
  data () {
    const beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 1)
    beginDate.setHours(6, 0, 0, 0)
    const endDate = new Date()
    endDate.setHours(6, 0, 0, 0)

    return {
      timelineChartData: {
        datasets: []
      },
      sleepChartData: {
        datasets: []
      },
      oneWeekChartData: {
        datasets: []
      },
      isLoading: true,
      timelineLength: 24,
      timelineBegin: null,
      timelineEnd: null,
      oneweekTimelineBegin: Math.floor(beginDate.getTime() / 1000),
      oneweekTimelineEnd: Math.floor(endDate.getTime() / 1000),
      sleepScoreTextData: []
    }
  },
  watch: {
    targetUserId: function() {
      this.loadTimelineData()
    },
    timelineLength: function() {
      this.loadTimelineData()
    },
  },
  methods: {
    loadTimelineData: async function() {
      try {
        this.timelineEnd = Math.floor(Date.now() / 1000)
        this.timelineBegin = this.timelineEnd - this.timelineLength * 60 * 60

        const response = await apiGet(
          `/api/target_user/timeline?target_user_id=${this.targetUserId}&begin=${this.timelineBegin}&end=${this.timelineEnd}`,
          this.$router.app.$cookies
        )
        this.renderTimelineData(response.data.life_log)
        this.renderSleepData(response.data.sleep, response.data.sleep_score_texts)

        const oneWeekResponse = await apiGet(
          `/api/target_user/one_week_life_log?target_user_id=${this.targetUserId}`,
          this.$router.app.$cookies
        )
        this.renderOneWeekTimelineData(oneWeekResponse.data)
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    renderTimelineData: function(lifeLogDataResponse) {
      if (typeof lifeLogDataResponse === "undefined") {
        this.timelineChartData = {
          datasets: []
        }
        return
      }

      const timelineDataList = []
      const timelineColorList = []

      let beforeData = null
      lifeLogDataResponse.forEach(lifeLogData => {
        if (beforeData !== null) {
          if (beforeData.detection === 1 || beforeData.sleepStage === 0) {
            timelineDataList.push({
              x: [new Date(beforeData.stateTime * 1000), new Date(lifeLogData.stateTime * 1000)],
              y: '活動あり'
            })
            timelineColorList.push(timelineColorMap['活動あり'])
          } else if (beforeData.detection === 0) {
            timelineDataList.push({
              x: [new Date(beforeData.stateTime * 1000), new Date(lifeLogData.stateTime * 1000)],
              y: '活動なし'
            })
            timelineColorList.push(timelineColorMap['活動なし'])
          } else if (beforeData.sleepStage === 1 || beforeData.sleepStage === 2) {
            timelineDataList.push({
              x: [new Date(beforeData.stateTime * 1000), new Date(lifeLogData.stateTime * 1000)],
              y: '睡眠中'
            })
            timelineColorList.push(timelineColorMap['睡眠中'])
          }
        }
        beforeData = lifeLogData
      })

      this.timelineChartData = {
        labels: ['活動あり','睡眠中','活動なし'],
        datasets: [
          {
            data: timelineDataList,
            backgroundColor: timelineColorList,
            borderWidth: 0,
            barPercentage: 0.5
          },
        ]
      }
    },
    renderSleepData: function(sleepDataResponse, sleepScoreTextData) {
      if (typeof sleepDataResponse === "undefined") {
        this.sleepChartData = {
          datasets: []
        }
        return
      }

      const sleepDataList = []
      const sleepColorList = []
      sleepDataResponse.forEach(sleepData => {
        let scoreText = ''
        sleepScoreTextData.forEach((sleepScoreText) => {
          if (sleepData.sleepScore >= sleepScoreText.score_min) {
            scoreText = sleepScoreText.text
          }
        })
        // temporary show raw score
        scoreText = '睡眠スコア:' + String(sleepData.sleepScore)

        let beforeTime = 0
        let beforeStage = 0
        sleepData.stages.forEach((stage, index) => {
          if (beforeTime !== 0){
            sleepDataList.push({
              x: [new Date(beforeTime * 1000), new Date(stage.time * 1000)],
              y: 1,
              label: sleepLabels[beforeStage],
              scoreText: scoreText
            })
            sleepColorList.push(sleepColorMap[beforeStage])
          }
          beforeTime = stage.time
          beforeStage = stage.stage

          if (index === sleepData.stages.length - 1) {
            sleepDataList.push({
              x: [new Date(beforeTime * 1000), new Date(sleepData.wakeup * 1000)],
              y: 1,
              label: sleepLabels[beforeStage],
              scoreText: scoreText
            })
            sleepColorList.push(sleepColorMap[beforeStage])
          }
        })
      })
      this.sleepChartData = {
        datasets: [
          {
            data: sleepDataList,
            backgroundColor: sleepColorList,
            barPercentage: 0.5
          },
        ]
      }
    },
    renderOneWeekTimelineData: function(oneWeekData) {
      if (oneWeekData === null) {
        this.oneWeekChartData = {
          datasets: []
        }
        return
      }

      const timelineDataList = []
      const timelineColorList = []
      for (let currentIndex = 0; currentIndex < oneWeekData.length; currentIndex++) {
        const dateString = this.getDayStringNDaysAgo(oneWeekData.length - currentIndex)
        let beforeData = null
        oneWeekData[currentIndex].forEach(lifeLogData => {
          const fakeDiff = oneDayUnixTime * (oneWeekData.length - currentIndex - 1)
          if (beforeData !== null) {
            const fakeStartTime = beforeData.stateTime * 1000 + fakeDiff
            const fakeEndTime = lifeLogData.stateTime * 1000 + fakeDiff

            if (beforeData.detection === 1 || beforeData.sleepStage === 0) {
              timelineDataList.push({
                x: [new Date(fakeStartTime), new Date(fakeEndTime)],
                y: dateString,
                label: '活動あり'
              })
              timelineColorList.push(timelineColorMap['活動あり'])
            } else if (beforeData.detection === 0) {
              timelineDataList.push({
                x: [new Date(fakeStartTime), new Date(fakeEndTime)],
                y: dateString,
                label: '活動なし'
              })
              timelineColorList.push(timelineColorMap['活動なし'])
            } else if (beforeData.sleepStage === 1 || beforeData.sleepStage === 2) {
              timelineDataList.push({
                x: [new Date(fakeStartTime), new Date(fakeEndTime)],
                y: dateString,
                label: '睡眠中'
              })
              timelineColorList.push(timelineColorMap['睡眠中'])
            }
          }
          beforeData = lifeLogData
        })
      }

      this.oneWeekChartData = {
        datasets: [
          {
            data: timelineDataList,
            backgroundColor: timelineColorList,
            barPercentage: 0.5
          },
        ]
      }
    },
    getDayStringNDaysAgo: function(n) {
      const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土"]
      const today = new Date()
      const nDaysAgo = new Date(today.getTime() - (n * 24 * 60 * 60 * 1000))
      const month = String(nDaysAgo.getMonth() + 1)
      const day = String(nDaysAgo.getDate())
      const dayOfWeek = daysOfWeek[nDaysAgo.getDay()]
      return `${month}月${day}日(${dayOfWeek})`
    }
  },
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .chart-container {
  padding: 4em 1em;
}

.legend {
  margin-top: 1em;
  display: flex;
}

.legend-item {
  display: flex;
  margin-left: 4em;
  gap: 1em;
}

.legend-item span {
  font-size: 3vw;
}

.active-color-sample {
  width: 2em;
  height: 2em;
  background-color: rgba(255, 205, 85, 1.0);
}

.sleep-color-sample {
  width: 2em;
  height: 2em;
  background-color: rgba(100, 105, 205, 1);
}

.non-active-color-sample {
  width: 2em;
  height: 2em;
  background-color: rgba(190, 190, 190, 1.0);
}

.non-rem-color-sample {
  width: 2em;
  height: 2em;
  background-color: rgba(150, 190, 250, 1.0);
}

.rem-color-sample {
  width: 2em;
  height: 2em;
  background-color: rgba(55, 90, 180, 1.0);
}

.awake-color-sample {
  width: 2em;
  height: 2em;
  background-color: rgba(255, 215, 0, 1.0);
}
}
@media all and (min-width: 480px) {
  .chart-container {
    padding: 4em;
  }

  .legend {
    margin-top: 1em;
    display: flex;
  }

  .legend-item {
    display: flex;
    margin-left: 4em;
    gap: 1em;
  }

  .legend-item span {
    font-size: 14px;
  }

  .active-color-sample {
    width: 2em;
    height: 2em;
    background-color: rgba(255, 205, 85, 1);
  }

  .sleep-color-sample {
    width: 2em;
    height: 2em;
    background-color: rgba(100, 105, 205, 1.0);
  }

  .non-active-color-sample {
    width: 2em;
    height: 2em;
    background-color: rgba(190, 190, 190, 1);
  }

  .non-rem-color-sample {
    width: 2em;
    height: 2em;
    background-color: rgba(150, 190, 250, 1);
  }

  .rem-color-sample {
    width: 2em;
    height: 2em;
    background-color: rgba(55, 90, 180, 1);
  }

  .awake-color-sample {
    width: 2em;
    height: 2em;
    background-color: rgba(255, 215, 0, 1.0);
  }
}
</style>
