<template>
  <Header />
  <main>
    <div class="cont">
      <vue-element-loading :active="isLoading" background-color="rgba(255, 255, 255, .5)" color="#2c3e50"/>

      <div v-if="facility">
        <h1>{{ facility.name }}</h1>
        <FacilityAdminMenu :facilityId="Number($route.query.id)"/>
        <TargetUserSearchForm @searchTargetUsers="searchTargetUsers"/>
        <div>
          <div class="noresults" v-if="targetUsers.length === 0"><span>該当なし</span></div>
          <TargetUserList v-if="targetUsers.length !== 0" @showUserDetail="showUserDetail" :targetUsers="targetUsers"/>
          <TargetUserDetail v-bind:data="detailData" :targetUsers="targetUsers"/>
        </div>
        <div class="facility-info">
          <dl class="add">
            <dt>住所</dt>
            <dd v-if="facility.address">{{ facility.address }}</dd>
          </dl>
          <dl class="phone">
            <dt>電話番号</dt>
            <dd v-if="facility.tel">{{ facility.tel }}</dd>
          </dl>
          <dl class="bed">
            <dt>ベッド数</dt>
            <dd v-if="facility.bed_count">{{ facility.bed_count }}台</dd>
          </dl>
          <dl class="manager">
            <dt>施設長</dt>
            <dd v-if="facility.manager">{{ facility.manager }}</dd>
          </dl>
          <dl class="staff">
            <dt>担当</dt>
            <dd v-if="facility.person_in_charge">{{ facility.person_in_charge }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet } from '../../api'
import FacilityAdminMenu from '../FacilityAdmin/FacilityAdminMenu'
import TargetUserSearchForm from './TargetUserSearchForm'
import TargetUserList from './TargetUserList'
import TargetUserDetail from './TargetUserDetail'

export default {
  name: 'FacilityManager',
  components: {
    Header,
    Footer,
    FacilityAdminMenu,
    TargetUserSearchForm,
    TargetUserList,
    TargetUserDetail,
  },
  data() {
    return {
      facility: null,
      targetUsers: [],
      detailData: {
        targetUserId: null,
        startTime: new Date()
      },
      isLoading: true,
      targetUsersDebug: false,
      currentSearchQuery: `facility_id=${this.$route.query.id}`
    }
  },
  mounted() {
    this.isLoading = true
    this.loadFacility()
    this.startFetchingTargetUserList()
  },
  methods: {
    showUserDetail: function(targetUserId) {
      this.detailData = {
        targetUserId: targetUserId,
        startTime: new Date()
      }
    },
    loadFacility: async function() {
      this.isLoading = true
      try {
        const response = await apiGet(`/api/facility?id=${this.$route.query.id}`, this.$router.app.$cookies)
        this.facility = response.data
      } catch (error) {
        console.error(error)
      }
    },
    startFetchingTargetUserList: async function() {
      this.loadTargetUserList()
      const loadTargetUserListTimeIntarval = 60000
      const _this = this
      setInterval(function() {
        _this.loadTargetUserList()
      }, loadTargetUserListTimeIntarval)
    },
    loadTargetUserList: async function() {
      if (!Number.isInteger(Number(this.$route.query.id))) {
        return
      }
      try {
        const response = await apiGet(`/api/target_user/search?${this.currentSearchQuery}`, this.$router.app.$cookies)
        this.targetUsers = response.data
        if (this.targetUsersDebug) {
          this.targetUsers.forEach((user) => {
            if (user.id === 1) {
              user.status = 3
            }
          })
          this.targetUsers = this.targetUsers.concat(this.targetUsers)
          this.targetUsers = this.targetUsers.concat(this.targetUsers)
          this.targetUsers = this.targetUsers.concat(this.targetUsers)
          this.targetUsers = this.targetUsers.concat(this.targetUsers)
        }
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },
    searchTargetUsers: function(targetUsers, query) {
      this.targetUsers = targetUsers
      this.currentSearchQuery = query
    }
  }
}
</script>
<style scoped>
@media all and (max-width: 480px) {
  .facility-info {
    padding-bottom: 4vw;
  }
  .facility-info dl {
    font-size: 3vw;
    background: rgb(255,255,255);
    border-bottom: rgb(160, 180, 205) solid 1px;
  }
  .facility-info dl:first-child {
    border-top: rgb(160, 180, 205) solid 1px;
  }
  .facility-info dl dt {
    padding: .2em .5em;
    background: rgb(240, 245, 255);
    text-align: center;
  }
  .facility-info dl dd {
    padding: .2em .5em;
  }
  .noresults {
    text-align: center;
    font-size: 4vw;
    padding: 2em 0 4em;
  }
  .noresults span {
    display: block;
    width: 80%;
    margin: auto;
    padding: 1em 0;
    border-top: rgb(200,200,200) double 3px;
    border-bottom: rgb(200,200,200) double 3px;
  }
}
@media all and (min-width: 480px) {
  .facility-info {
    display: flex;
    flex-flow: row wrap;
  }
  .facility-info dl {
    font-size: 1vw;
    border: rgb(160, 180, 205) solid 1px;
    border-left: none;
    background: rgb(255,255,255);
  }
  .facility-info dl.add {
    width: 36%;
  }
  .facility-info dl.phone ,
  .facility-info dl.bed {
    width: 15%;
  }
  .facility-info dl.manager ,
  .facility-info dl.staff {
    width: 17%;
  }
  .facility-info dl:first-child {
    border-left: rgb(160, 180, 205) solid 1px;
  }
  .facility-info dl dt {
    background: rgb(240, 245, 255);
    padding: .5em;
    text-align: center;
  }
  .facility-info dl dd {
    padding: .5em;
  }
  .form {
    padding: 0 1vw 3vw;
  }
  .form > div {
    display: flex;
    flex-flow: row wrap;
    font-size: 1vw;
    padding-bottom: 1em;
  }
  .form div p {
    padding-right: 1em;
  }
  .form div label {
    display: block;
  }
  .form div input,
  .form div select {
    font-size: 1vw;
    padding: .2em;
  }
  .form .btn {
    padding: 0 0 0 20vw;
  }
  .noresults {
    text-align: center;
    font-size: 1vw;
    padding: 2em 0 4em;
  }
  .noresults span {
    display: block;
    width: 60%;
    margin: auto;
    padding: 1em 0;
    border-top: rgb(200,200,200) double 3px;
    border-bottom: rgb(200,200,200) double 3px;
  }
}
</style>
