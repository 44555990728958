<template>
  <Bar :data="data" :options="options"/>
</template>

<script>

import {
  Chart as ChartJS,
  TimeScale,
  BarElement,
  Tooltip,
} from 'chart.js'

import { Bar } from 'vue-chartjs'
import 'chartjs-adapter-moment';

ChartJS.register(TimeScale, BarElement, Tooltip)

export default {
  name: 'OneWeekTimelineChart',
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    },
    begin: {
      type: Number,
      require: true
    },
    end: {
      type: Number,
      require: true
    }
  },
  components: {
    Bar
  },
  data() {
    const xMax = new Date(this.end * 1000)
    const xMin = new Date(this.begin * 1000)

    return {
      options: {
        indexAxis: 'y',
        responsive: true,
        borderWidth: 0,
        animation: {
          duration: 0
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: function(tooltipItem) {
                const beginDate = tooltipItem[0].raw.x[0]
                const beginHours = String(beginDate.getHours()).padStart(2, '0')
                const beginMinutes = String(beginDate.getMinutes()).padStart(2, '0')

                const endDate = tooltipItem[0].raw.x[1]
                const endHours = String(endDate.getHours()).padStart(2, '0')
                const endMinutes = String(endDate.getMinutes()).padStart(2, '0')

                return `${beginHours}:${beginMinutes} ~ ${endHours}:${endMinutes}`
              },
              label: function(tooltipItem) {
                return tooltipItem.raw.label
              },
            }
          }
        },
        scales: {
          x: {
            min: xMin,
            max: xMax,
            type: 'time',
            time: {
              unit: 'hour'
            },
            ticks: {
              callback: function(unixtime) {
                let dateTime = new Date(unixtime);
                return dateTime.toLocaleTimeString().slice(0,-3)
              }
            }
          },
          y: {
            beginAtZero: true,
            stacked: true,
            ticks: {
              font: function(context){
                const size = Math.round(context.chart.width / 64)
                return {
                  size: size
                }
              }
            }
          }
        },
      }
    }
  }
}
</script>
