<template>
  <div class="result-table">
    <table v-if="residents.length > 0">
      <thead>
        <tr>
          <th>部屋名</th>
          <th>氏&nbsp;&nbsp;名</th>
          <th>ラベル</th>
          <th>備&nbsp;&nbsp;考</th>
          <th>編&nbsp;&nbsp;集</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="resident in residents" :key="resident.id">
          <td>{{ resident.room_name }}</td>
          <td>{{ resident.name }}</td>
          <td>{{ resident.main_label }}</td>
          <td>{{ resident.sub_label }}</td>
          <td>
            <button v-on:click="editResident(resident.id)">編集</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ResidentsSearchResult',
  props: {
    residents: {
      type: Array,
      required: true
    },
    facilityId: {
      type: Number,
      required: true
    }
  },
  methods: {
    editResident: function (residentId) {
      this.$router.push(`/facility_admin/edit_resident?id=${residentId}&facility_id=${this.facilityId}`)
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .result-table {
    display: inline-block;
    min-width: 100%;
  }
  .result-table table {
    width: 100%;
    border: rgb(150,150,150) solid 1px;
    font-size: 3vw;
    border-collapse: collapse;
  }
  .result-table table thead th {
    background: rgb(75, 130, 200);
    text-align: center;
    font-size: 2.4vw;
    color: rgb(255,255,255);
    padding: .2em .5em;
    border-right: rgb(255,255,255) solid 1px;
  }
  .result-table table thead th:last-child {
    border-right: none;
  }
  .result-table table td {
    padding: .5em;
    border-right: rgb(200,200,200) solid 1px;
    border-bottom: rgb(200,200,200) solid 1px;
    background: rgb(255,255,255);
  }
  .result-table table td:last-child {
    border-right: none;
    text-align: center;
  }
  .result-table table tr:nth-of-type(2n) td {
    background: rgb(240, 245, 255);
  }
  .result-table table button {
    padding: .4em;
  }
}
@media all and (min-width: 480px) {
  .result-table {
    width: 60vw;
    margin: auto;
  }
  .result-table table {
    width: 100%;
    border: rgb(150,150,150) solid 1px;
    font-size: 1vw;
    border-collapse: collapse;
  }
  .result-table table thead th {
    background: rgb(75, 130, 200);
    text-align: center;
    color: rgb(255,255,255);
    padding: .2em .5em;
    border-right: rgb(255,255,255) solid 1px;
  }
  .result-table table thead th:last-child {
    border-right: none;
  }
  .result-table table td {
    padding: .2em .5em;
    border-right: rgb(200,200,200) solid 1px;
    border-bottom: rgb(200,200,200) solid 1px;
    background: rgb(255,255,255);
  }
  .result-table table td:last-child {
    border-right: none;
    text-align: center;
  }
  .result-table table tr:nth-of-type(2n) td {
    background: rgb(240, 245, 255);
  }
}
</style>
