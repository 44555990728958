<template>
  <Line :data="data" :options="options"/>
</template>

<script>

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'

import { Line } from 'vue-chartjs'
import 'chartjs-adapter-moment';

ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Title, Filler, Tooltip, Legend)

export default {
  name: 'ActivityChart',
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    },
    startTime: {
      type: Date,
      required: true
    }
  },
  components: {
    Line
  },
  watch: {
    startTime: async function(newVal) {
      this.options = this.generateOptions(newVal)
    }
  },
  data() {
    return {
      options: this.generateOptions(new Date())
    }
  },
  methods: {
    generateOptions: function(now) {
      const nowDate = new Date(now)
      const xMax = new Date(nowDate.getTime() + 61 * 1000)
      return {
        responsive: true,
        borderColor: '#3cb371',
        backgroundColor: 'rgba(60, 179, 113, 0.2)',
        barPercentage: 5.0,
        aspectRatio: 3.0,
        animation: {
          duration: 0
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
        },
        elements: {
          point:{
            radius: 0
          },
        },
        showLine: true,
        fill: true,
        tension: 0.2,
        scales: {
          x: {
            min: now,
            max: xMax,
            type: 'time',
            time: {
              parser: 'YYYY-MM-DDTHH:mm',
              unit: 'second'
            },
            ticks: {
              callback: function(unixtime) {
                const tickTime = Math.floor((unixtime - now) / 1000)
                if (tickTime % 10 !== 0) {
                  return ''
                }
                return tickTime === 0 ? '0秒' : tickTime
              }
            }
          },
          y: {
            type: 'linear',
            display: false,
            max: 100,
            min: 0,
            beginAtZero: true,
            ticks: {
              callback: function() {
                return ''
              }
            }
          }
        }
      }
    }
  }
}
</script>
